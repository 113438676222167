import { useRouteQuery } from '@shared/composables/useRouteQuery'
import type { Ref } from 'vue'

export interface IPagination {
  page: number
  pageSize: number
}

export interface IPaginationOptions {
  initial?: Partial<IPagination>
  persist?: string | boolean
}

export function usePagination(options: IPaginationOptions = {}) {
  const { initial: { page = 1, pageSize = 25 } = {}, persist = false } = options

  let query: Ref<IPagination> | undefined

  if (persist) {
    query = useRouteQuery<IPagination>(
      typeof persist === 'string' ? persist : 'pagination',
      () => ({ page, pageSize }),
      {
        mode: 'push',
        transform: (value) => ({
          page: Number(value.page),
          pageSize: Number(value.pageSize),
        }),
      },
    )
  }

  const pagination = customRef<IPagination>((track, trigger) => {
    let _pagination = { page, pageSize }

    return {
      get() {
        track()
        return getValue()
      },
      set(value) {
        if (isEqual(getValue(), value)) return
        setValue(value)

        trigger()
      },
    }

    function getValue() {
      return persist ? query!.value : _pagination
    }

    function setValue(value: IPagination) {
      if (persist) {
        query!.value = { ...value }
      } else {
        _pagination = { ...value }
      }
    }

    function isEqual(a: IPagination, b: IPagination) {
      return a.page === b.page && a.pageSize === b.pageSize
    }
  })

  const changePage = (
    page: number,
    rows: number = pagination.value.pageSize,
  ) => {
    pagination.value = { page, pageSize: rows }
  }

  return { pagination, changePage }
}
